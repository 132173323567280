<template>
    <div class="table_container">
        <v-simple-table dense>
            <template v-slot:default>
                <thead >
                    <tr >
                        <th  v-for="(field,index) in fields" :key="index" v-if="field.type !== 'hidden'">
                            {{ field.label }}
                        </th>
                        <!-- Actions -->
                        <th  v-if="canDelete || canEdit" class='tableActions'></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id"  class='select-row'> 
                        <td @click="$emit('selectItem', item), setActive(item)" v-for="(field,index) in fields" :key="index" v-if="field.type !== 'hidden'">

                            <div class="no-link" :class="(field.getClass) ?field.getClass(item):''" v-if="!field.getLink && field.type!=='date'">{{ field.getValue(item) }}</div>

                            <router-link class="link" v-if="field.getLink" :to="field.getLink(item)" > {{ field.getValue(item) }}</router-link> 
                            
                        </td>
                        <!-- Actions -->
                        <td   v-if="canDelete || canEdit || canSelect" class='tableActions'>
                            <v-icon v-if='canSelect' :class="{selected:isSelected == item.id}" class='not-selected'>mdi-play</v-icon>
                            
                            <a v-if="canEdit" @click="$emit('edit', item)">
                                <v-icon color="gray">mdi-pencil-outline</v-icon>
                            </a>
                            <a v-if="canDelete" @click="$emit('delete', item)">
                                <v-icon color="gray">mdi-delete-outline</v-icon>
                            </a>
                        </td>
                    </tr>
                </tbody> 
            </template>
        </v-simple-table>
    </div>
</template>
<style scoped>
    .not-selected {
        color: transparent;
        position: absolute;
        right: 20px;
    }
    .selected.not-selected {
        color: #892EFF;
    }
    .tableActions {
        width: 90px;
    }
</style>
<script>
export default {
    props: ['fields', 'data', 'canEdit', 'canDelete' ,'canSelect', 'reset'],
    data() {
        return {
            isSelected:0
        }
    },
    watch:{
        reset: function(to, from){
            this.isSelected = 0
        }
    },
    methods: {
        setActive(item){
            this.isSelected = item.id
        }
    }
}
</script>