<template>
	<div class="tab devices" data-aos="fade">
		<v-container>
			<v-row>
				<v-col cols='12'>
					<ul class="no-padd-left" v-if="deviceTypes!==null">
						<li 
						v-ripple
						@click="isActive = item" 
						v-for="item in deviceTypes" 
						:key="item.id" 
						v-bind:class="{tab_active:isActive === item}"
						>
						{{item.name}}
						</li>
					</ul>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12"  v-if='isActive.code === "VideoStreaming"'>
					<video-streaming data-aos="fade" :deviceTypeId="isActive.id"></video-streaming>
				</v-col>
				<v-col cols="12"  v-if='isActive.code === "ControlBoard"'>
					<control-board data-aos="fade" :deviceTypeId="isActive.id"></control-board>
				</v-col>
				<!-- <v-col cols="12"  v-if='isActive.code === "BillAcceptor"'>
					<bill-acceptor data-aos="fade" :deviceTypeId="isActive.id"/>
				</v-col>	 -->
			</v-row>
		</v-container>
	</div> 
</template>
<script>
import VideoStreamingTab from './VideoStreamingTab.vue'
import ControlBoardTab from './ControlBoardTab.vue'
export default {
	components: {
		'video-streaming':VideoStreamingTab,
		'control-board':ControlBoardTab
	},
	data(){
		return {
			deviceTypes: null, //holds all device types
			isActive:'',
		}
	},
	mounted(){
		this.getDeviceTypes();
	},
	methods: {
		getDeviceTypes(){
			axios.get('deviceTypes')
					.then(res=>{
						let data = res.data;
						this.deviceTypes = data.filter(type=>(type.id !== 4 && type.id !==5) );
						this.isActive = this.deviceTypes[0];
					});
		}
	}
}
</script>