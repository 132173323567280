<template>
    <span  @click="openAddModal()">{{label}}<v-icon>mdi-plus</v-icon></span>
</template>
<script>
export default {
    props:['specificCanAdd', 'label'],
    data(){
        return {
           
        }
    },
    methods: {
        openAddModal(){
            this.$emit('openSpecificCanAdd', true);
        }
    }
}
</script>