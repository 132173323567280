<template>
    <div class="tab zones" style="margin-top: 20px;">
        <v-row>
            <v-col col="12">
                <div class="card card-purple" v-if="data" data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Zones</span>
                        <span class="float-right">
                            <add-new-item
                            :label='addBtnLabel'
                            @openSpecificCanAdd='openAddNewModal'
                            />
                        </span>
                    </div>
                    <crud-table class="gen_table" :data='data' :fields='fields' :canDelete="true" @delete="openDeleteModal"></crud-table>
                </div>
            </v-col>
        </v-row>    
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if="editing">
                    <div class="modal_header">
                        <p>Zone</p>
                        <p v-if="!temp.id">Add New Link</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" >
                                    <v-select 
                                    v-if="zoneTypes" 
                                    :items="zoneTypes"
                                    item-text="name" 
                                    item-value="name"
                                    label="Zone Type"
                                    ref='zone'
                                    v-model="tempZoneType"
                                    :rules="[() => !!tempZoneType || 'This field is required']"
                                    @change='getCasinoZoneByTypes'
                                    required
                                    outlined/>
                                    <v-select 
                                    :disabled="!casinoZoneTypes" 
                                    :items="(casinoZoneTypes) ? casinoZoneTypes : []"
                                    item-text="name" 
                                    item-value="id"
                                    label="Casino Zone"
                                    ref='name'
                                    v-model="temp.CasinoZoneId"
                                    :rules="[() => !!temp.CasinoZoneId || 'This field is required']"
                                    required
                                    outlined/>
                                </div> 
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :disabled="loading" :elevation="0" color="green" dark @click="createItem(temp)">
                           Add New Link
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :disabled="loading" :elevation="0" color="green" dark @click="updateItem(temp, true)">
                            Save
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :disabled="loading" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save and Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Zone</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>            
    </div>
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTable from '@/components/tables/CrudTable.vue';


export default {
    components: {
        'add-new-item': AddNewItem,
        'crud-table':CrudTable,
    },
    props:['egmData'],
    data(){
        return {
            data: null, //Casino EGM Zones
            casinoZoneTypes: null, //Casino Zone Types
            zoneTypes: null,
            editing: false,
            deleting: false,
            selected: null,
            loading: false,
            temp: {},
            fields: [
                
                {
                    label: 'Name',
                    getValue: item => item.casinoZone.name,
                    type: 'text',
                    required: true,
                    ref:'name',
                },
                {
                    label: 'Type',
                    getValue: item => item.casinoZone.type.name,
                },
                {
                    label: 'Status',
                    getValue: item => item.casinoZone.status,
                },
                {
                    label: 'Zone',
                    type: 'hidden',
                    required: true,
                    ref:'zone',
                },
            ],
            addBtnLabel:'Add New Link',
            tempZoneType:null
            
        }
    },
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
                this.tempZoneType = null;
            }
        },
    },
    mounted(){
        this.getCasinoZones();
        //this.getCasinoZoneTypes();
        this.getZoneTypes();
    },
    methods: {
        
        getCasinoZones(){
            axios.get('admin/egms/' + this.$route.params.egm_id + '/zones')
                .then(res=>{
                    this.data = res.data
                });
        },

        // getCasinoZoneTypes(){
        //     axios.get('admin/casino/'+this.egmData.casinoId+'/zones')
        //         .then(res=>{
        //             this.casinoZoneTypes = res.data
        //         })
        // },

        getCasinoZoneByTypes(){
            axios.get('admin/casino/'+this.egmData.casinoId+'/zones?type='+this.tempZoneType)
                .then(res=>{
                    this.casinoZoneTypes = res.data
                })
        },

        getZoneTypes(){
            axios.get('casinoZoneTypes')
                .then(res=>{
                    this.zoneTypes = res.data
                })
        },

        openAddNewModal(){
            this.editing = true;
        },

        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },

        validate(){

            let valid = true;

            this.fields.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },
        createItem(temp, keepOpen){
            this.temp['EGMachineId'] = this.egmData.id;
            this.loading = true;
            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            axios.post('egmCasinoZones', temp)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                    this.getCasinoZones();
                    if(!keepOpen){
                        this.closeModals();
                    }
                    
                    this.loading = false;
                    this.$emit('refreshStats');
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;
                 });
        },
        deleteItem(item) {
            
            axios.delete('egmCasinoZones/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCasinoZones();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },
        closeModals() {
            this.temp = {};
            this.tempZoneType = null;
            this.selected = null;
            this.editing = false;
            this.deleting = false;
        },
    }
}
</script>