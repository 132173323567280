<template>
<div class="page casino">
	<div class="header">
		<v-container>
			<v-row>
				<v-col cols='12'>
					<div class="entity" v-if="casinoData" data-aos="fade">
						<div class="title">{{casinoData.name}}</div>
						<div class="subtitle">{{casinoData.companyName}}</div>
					</div>
				</v-col>
			</v-row>
			<v-row class="stats_container" v-if="casinoData" data-aos="fade">
				<v-col cols="2">
					<v-card class="nav_card_graph">
						<p>Zones</p>
						<p>12</p>	
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
	
	<div class="navigation navigation-green">
		<v-container>
			<v-row>
				<v-col cols="12">
					<tab-nav :tabNames="tabNames"></tab-nav>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div class="content">
		<router-view ></router-view>
	</div>	
</div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue';

export default {
	components: {
		'tab-nav':TabNav
	},

	data(){
		return {
			casinoData: null, //holds casino name and company name
			tabNames: [	{label:'EGMs',
						route:'egm'
						},
						{label:'ZONES',
						route: 'zone'
						},
						
						{label:'DEVICES',
						route:'device'
						}
					]
		}
	},
	mounted() {
		var self = this;
		axios.get('casinos/'+this.$route.params.casino_id)
			 .then(function(res){
				 self.casinoData = res.data
			 });
	},
	methods: {

	}
}
</script>