<template>
<v-row>
    <v-col cols="8">
        <div class="card card-green">
            <div class="card_header">
                <span class="float-left">Control Board</span>
                <span class="float-right">
                    <add-new-item
                        :label='addBtnLabel'
                        @openSpecificCanAdd='openAddNewModal(false)'
                    />
                </span>
            </div>
            <crud-table-select class="gen_table control_board" @selectItem="selectedDeviceSettings" :data='dataControlBoards' :fields='fieldsControlBoards' :canEdit="true" :canDelete="true"  :canSelect="true" @edit="openEditModal" @delete="openDeleteModal"/>    
        </div> 
    </v-col>
    <v-col cols="4" v-if="selectedDevice">
        <div class="card card-green">
            <div class="card_header">
                <span class="float-left">{{selectedDevice.serial}} Device Settings</span>
                <span class="float-right">
                    <add-new-item
                        :label='addBtnLabel'
                        @openSpecificCanAdd='openAddNewModal(true)'
                    />
                </span>
            </div>
            <div class="table_container gen_table">
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="setting in selectedDevice.settings" :key="setting.id">
                                <td>{{setting.type.name}}:</td>
                                <td>{{setting.intValue}} {{setting.stringValue }}</td>
                                <td class="float-right">
                                    <a @click="openEditDeviceSetting(setting)">
                                        <v-icon color="gray">mdi-pencil-outline</v-icon>
                                    </a>
                                    <a @click="openDeleteDeviceSetting(setting)">
                                        <v-icon color="gray">mdi-delete-outline</v-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>
    </v-col>
    <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p v-if='!editingDeviceSetting'>Control Board</p>
                        <p v-if='editingDeviceSetting'>Device Setting</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form v-if='!editingDeviceSetting'>
                            <div class="fields">
                                <div class="field">
                                    <v-text-field 
                                        label="Serial"
                                        v-model="temp.serial"
                                        :rules="[() => !!temp.serial || 'This field is required']"
                                        ref='serial'
                                        outlined
                                    />
                                    <v-select
                                        label='Manufacturer'
                                        :items="deviceManufacturers"
                                        item-text='name'
                                        item-value='id'
                                        v-model="temp.manufacturerId"
                                        :rules="[() => !!temp.manufacturerId || 'This field is required']"
                                        ref='manufacturer'
                                        outlined
                                    />
                                    <v-text-field 
                                        label="HW Version"
                                        v-model="temp.hwVersion"
                                        :rules="[() => !!temp.hwVersion || 'This field is required']"
                                        ref='hwVersion'
                                        outlined
                                    />
                                    <v-text-field 
                                        label="SW Version"
                                        v-model="temp.swVersion"
                                        :rules="[() => !!temp.swVersion || 'This field is required']"
                                        ref='swVersion'
                                        outlined
                                    />
                                    <v-text-field 
                                        label="API Key"
                                        v-model="temp.apiKey"
                                        :rules="[() => !!temp.apiKey || 'This field is required']"
                                        ref='apiKey'
                                        outlined
                                    />
                                    <v-text-field 
                                        label="MAC Address"
                                        v-model="temp.macAddress"
                                        :rules="[() => !!temp.macAddress || 'This field is required']"
                                        ref='macAddress'
                                        outlined
                                    />
                                    <!-- <v-text-field 
                                        label="Audit Interval"
                                        v-model="temp.remoteSettings.auditInterval"
                                        :rules="[() => !!temp.remoteSettings.auditInterval || 'This field is required']"
                                        ref='audit'
                                        outlined
                                    />
                                    <v-text-field 
                                        label="Status Interval"
                                        v-model="temp.remoteSettings.statusInterval"
                                        :rules="[() => !!temp.remoteSettings.statusInterval || 'This field is required']"
                                        ref='status'
                                        outlined
                                    />
                                    <v-text-field 
                                        label="Min Credits Start Session"
                                        v-model="temp.remoteSettings.minCreditStartSession"
                                        :rules="[() => !!temp.remoteSettings.minCreditStartSession || 'This field is required']"
                                        ref='minCreditStartSession'
                                        outlined
                                    /> -->
                                </div>  
                            </div>
                        </form>
                        <form v-if='editingDeviceSetting'>
                            <div class="fields">
                                <div class="field">
                                    <v-select
                                        label='Setting'
                                        :items="deviceSettingTypes"
                                        item-text='name'
                                        item-value='id'
                                        v-model="temp.typeId"
                                        :rules="[() => !!temp.typeId || 'This field is required']"
                                        ref='setting'
                                        outlined
                                    />
                                    <v-text-field 
                                        v-if="temp.typeId != 11"
                                        label="Integer Value"
                                        v-model="temp.intValue"
                                        :rules="[() => !!temp.intValue || 'This field is required']"
                                        ref='value'
                                        :disabled='(temp.typeId)?false:true'
                                        :placeholder='temp.typeId ? "Default Value: "+deviceSettingTypes.filter(device=>device.id === temp.typeId)[0].defaultIntValue : ""'
                                        outlined
                                    />

                                    <v-text-field 
                                        v-if="temp.typeId == 11"
                                        label="String Value"
                                        v-model="temp.stringValue"
                                        :rules="[() => !!temp.stringValue || 'This field is required']"
                                        ref='value'
                                        :disabled='(temp.typeId)?false:true'
                                        :placeholder='temp.typeId ? "Default Value: "+deviceSettingTypes.filter(device=>device.id === temp.typeId)[0].defaultIntValue : ""'
                                        outlined
                                    />
                                </div>  
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>

                        <!-- Control Board -->
                        <v-btn class="modal_btn"  v-if="!temp.id && !editingDeviceSetting" :elevation="0" color="green" dark @click="createItem(temp)">
                            Add New
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && !editingDeviceSetting" :elevation="0" color="green" dark @click="updateItem(temp)">
                            Save
                        </v-btn>

                        <!-- Device Settings -->
                        <v-btn class="modal_btn"  v-if="!temp.id && editingDeviceSetting" :elevation="0" color="red" dark @click="createDeviceSetting(temp)">
                            Add New
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && editingDeviceSetting" :elevation="0" color="red" dark @click="updateDeviceSetting(temp)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p v-if='!editingDeviceSetting'>Control Board</p>
                        <p v-if='editingDeviceSetting'>Device Setting</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn v-if="!editingDeviceSetting" class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                        <v-btn v-if="editingDeviceSetting" class="modal_btn" :elevation="0" color="green" dark @click="deleteDeviceSetting(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
</v-row>
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
import CrudTableSelect from '@/components/tables/CrudTableSelect.vue';

export default {
    components:{
        'add-new-item':AddNewItem,
        'crud-table':CrudTable,
        'crud-table-select':CrudTableSelect
    },
    props:['deviceTypeId'],
    data(){
        return {
            dataControlBoards: null, //holds device type data
            deviceManufacturers: null,
            deviceSettingTypes: null,
           
            fieldsControlBoards: [
                {
                    label: 'Serial',
                    getValue: item => item.serial,
                    type: 'text',
                    key: 'serial',
                    ref:'serial',
                    required: true,
                },

                {
                    label: 'Manufacturer',
                    getValue: item => (item.manufacturer) ? item.manufacturer.name : null,
                    type: 'text',
                    key: 'manufacturerId',
                    ref: 'manufacturer',
                    required: true,
                },

                {
                    label:' HW Version',
                    getValue: item => item.hwVersion,
                    type:'text',
                    key:'hwVersion',
                    ref:'hwVersion',
                    required:true
                },
                {
                    label:'SW Version',
                    getValue: item => item.swVersion,
                    type:'text',
                    key:'swVersion',
                    ref:'swVersion',
                    required:true
                },
                {
                    label: 'API Key',
                    getValue: item => item.apiKey,
                    type:'text',
                    key:'apiKey',
                    ref:'apiKey',
                    required:true
                },
                {
                    label: 'MAC Address',
                    getValue: item => item.macAddress,
                    type:'text',
                    key:'macAddress',
                    ref:'macAddress',
                    required:true
                },
                // {
                //     label: 'Audit Interval',
                //     getValue: item => (item.remoteSettings) ? item.remoteSettings.auditInterval : null,
                //     type:'text',
                //     key:'audit',
                //     ref:'audit',
                //     required:true
                // },
                // {
                //     label: 'Status Interval',
                //     getValue: item => (item.remoteSettings) ? item.remoteSettings.statusInterval : null,
                //     type:'text',
                //     key:'status',
                //     ref:'status',
                //     required:true
                // },
                // {
                //     label: 'Min Credits Start Session',
                //     getValue: item => (item.remoteSettings) ? item.remoteSettings.minCreditStartSession : null,
                //     type:'text',
                //     key:'minCreditStartSession',
                //     ref:'minCreditStartSession',
                //     required:true
                // }                                
            ],
            fieldsDeviceSettings:[
                {   
                    label:'Setting',
                    type: 'text',
                    ref:'setting',
                    required: true,
                },
                {   
                    label:'Value',
                    type: 'text',
                    ref:'value',
                    required: true,
                },
            ],
            selectedDevice:null,
            editing: false,
            editingDeviceSetting: false,
            deleting: false,
            selected: null,
            loading: false,
			temp:{},
            addBtnLabel:'Add New',
        }
    },
    mounted(){
        this.getControlBoards();
        this.getDeviceManufacturers();
        this.getDeviceSettingTypes()
    },
    methods:{
        getControlBoards(){
            axios.get('admin/casino/'+this.$route.params.casino_id+'/devices?typeId='+this.deviceTypeId)
                .then(res=>{
                    this.dataControlBoards = res.data;
                    if(this.selectedDevice){
                        this.refreshSelectedDevice(this.selectedDevice); 
                    }
                })
        },
        getDeviceManufacturers(){
            axios.get('deviceManufacturers')
            .then(res=>{
                this.deviceManufacturers = res.data;
            })
        },
        getDeviceSettingTypes(){
            axios.get('deviceSettingTypes?deviceTypeId=2')
                .then(res=>{
                    this.deviceSettingTypes = res.data;
                })
        },
        validate(item){

            let valid = true;

           item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);
                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },
        selectedDeviceSettings(item){
            this.selectedDevice = item;
        },
        openAddNewModal(isDeviceSetting){
            this.editing = true;
            if(isDeviceSetting){
                this.editingDeviceSetting = true;
            } else {
                this.editingDeviceSetting = false;
                this.temp.remoteSettings = {};
            }
        },
        openEditModal(item){
            let obj = JSON.parse(JSON.stringify(item));
            delete obj.billAcceptorChannels;
            delete obj.manufacturer;
            delete obj.type;
            delete obj.videoStreams;
            
            this.temp = obj;
            this.editing = true;
        },
        openEditDeviceSetting(item){
            let obj = JSON.parse(JSON.stringify(item));
            delete obj.type;
            this.temp = obj;
            this.editing = true;
            this.editingDeviceSetting = true;
        },
        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },
        openDeleteDeviceSetting(selected){
            this.selected = selected
            this.deleting = true;
            this.editingDeviceSetting = true;
        },

        //Control Board CRUD
        createItem(item){
          this.loading = true;  
          if(!this.validate(this.fieldsControlBoards)) {
              Vue.toasted.error('Please fill in the form');
              this.loading = false;
              return; 
          }
          item.casinoId = this.$route.params.casino_id;
          item.typeId = this.deviceTypeId;
          axios.post('devices', item)
              .then(res=> {
                  Vue.toasted.success('Item created.');
                  this.getControlBoards();
                  this.closeModals();
                  this.loading = false;
              })
              .catch(err => {
                  
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error processing your request'); 
                  }

                  this.loading = false;

                });
        },
        updateItem(item) {
            // Validation 
            this.loading = true;            
            if(!this.validate(this.fieldsControlBoards)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            
            axios.put('devices/'+item.id, item)
                 .then(res => {

                    Vue.toasted.success('Item updated.');
                    this.closeModals();
                    this.getControlBoards();                     
                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },
        deleteItem(item) {
            //console.log(item);
            axios.delete('devices/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getControlBoards();
                })
                .catch(err => {
                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error deleting this item.')
                    }
                   
                });

        },

        //Device Settings CRUD
        createDeviceSetting(item){
            this.loading = true;  
            if(!this.validate(this.fieldsDeviceSettings)) {
                Vue.toasted.error('Please fill in the form');
                this.loading = false;
                return; 
            }
            item.deviceId = this.selectedDevice.id;   
            console.log(item);   
            axios.post('deviceSettings', item)
                .then(res=> {
                    Vue.toasted.success('Device setting created.');
                    this.getControlBoards();
                    this.closeModals();
                    this.loading = false;
                })
                .catch(err => {
                    
                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });
        },
        updateDeviceSetting(item) {
            // Validation 
            this.loading = true;            
            if(!this.validate(this.fieldsDeviceSettings)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            
            axios.put('deviceSettings/'+item.id, item)
                 .then(res => {

                    Vue.toasted.success('Item updated.');
                   
                    this.getControlBoards();           
                    this.closeModals();         
                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },
        deleteDeviceSetting(item) {
            console.log(item);
            axios.delete('deviceSettings/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getControlBoards();
                })
                .catch(err => {
                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error deleting this item.')
                    }
                   
                });

        },

        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.editingDeviceSetting = false;
            this.deleting = false;
        },

        refreshSelectedDevice(item){
            this.selectedDevice = this.dataControlBoards.filter(el=>el.id === item.id)[0];
        }
    }
}
</script>