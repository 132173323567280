<template>
    <ul class="sys_info">
        <li v-for="(field,index) in fields" :key="index">
            <span>{{field.label}}</span>
            <span class="float-right">{{field.getValue(data)}}</span>
        </li>
    </ul>
</template>
<script>
export default {
    props:['data','fields'],
    data(){
        return {

        }
    }
}
</script>