<template>
	<div class="tab zones" data-aos="fade">
		<v-container>
			<v-row>
				<v-col xs='12' sm='12' md='12' lg='4' xl='4'>
					<div class="card card-green" v-if="dataPhysical" data-aos="fade">
						<crud-zone
						label="Physical"
						endpoint="casinoZones/"
						@refresh="getCasinoZonesPhysical"
						:fields="fieldsPhysical"
						:data="dataPhysical"
						:canAdd="true"
						:canEdit="true"
						:canDelete='true' />
					</div>
				</v-col>
				<v-col xs='12' sm='12' md='12' lg='4' xl='4'>
					<div class="card card-green" v-if="dataVirtual" data-aos="fade">
						<crud-zone
						label="Virtual"
						endpoint="casinoZones/"
						@refresh="getCasinoZonesVirtual"
						:fields="fieldsVirtual"
						:data="dataVirtual"
						:canAdd="true"
						:canEdit="true"
						:canDelete='true' />
					</div>
				</v-col>
				<v-col xs='12' sm='12' md='12' lg='4' xl='4'>
					<div class="card card-green" v-if="dataPlay" data-aos="fade">
						<crud-zone
						label="Play"
						endpoint="casinoZones/"
						@refresh="getCasinoZonesPlay"
						:fields="fieldsPlay"
						:data="dataPlay"
						:canAdd="true"
						:canEdit="true"
						:canDelete='true' />
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div> 
</template>
<script>
import CrudZone from '@/components/CrudZone.vue';
export default {
	components: {
		'crud-zone': CrudZone
	},
	data(){
		return {
			dataPhysical: null,
			dataVirtual: null,
			dataPlay:null,
			fieldsPhysical: [
				{
					label: 'Name',
					getValue: item =>item.name,
					type:'text',
					key:'name',
					required:true
				},
				{
					label: 'Code',
					getValue: item =>item.code,
					type:'text',
					key:'code',
					required:true,
				},
				{
					label: 'Casino',
					getValue: item => this.$route.params.casino_id,
					type: 'hidden',
					key: 'casinoId',
				},
				{
					label: 'Type',
					getValue: item => 'physical',
					type: 'hidden',
					key: 'type',
				},
			],
			fieldsVirtual : [
				{
					label: 'Name',
					getValue: item =>item.name,
					type:'text',
					key:'name',
					required:true
				},
				{
					label: 'Code',
					getValue: item =>item.code,
					type:'text',
					key:'code',
					required:true,
				},
				{
					label: 'Casino',
					getValue: item => this.$route.params.casino_id,
					type: 'hidden',
					key: 'casinoId',
				},
				{
					label: 'Type',
					getValue: item => 'virtual',
					type: 'hidden',
					key: 'type',
				},
			],
			fieldsPlay : [
				{
					label: 'Name',
					getValue: item =>item.name,
					type:'text',
					key:'name',
					required:true
				},
				{
					label: 'Code',
					getValue: item =>item.code,
					type:'text',
					key:'code',
					required:true,
				},
				{
					label: 'Casino', 
					getValue: item => this.$route.params.casino_id,
					type: 'hidden',
					key: 'casinoId',
				},
				{
					label: 'Type',
					getValue: item => 'play',
					type: 'hidden',
					key: 'type',
				},
			]
		}
	},
	mounted(){
		this.getCasinoZonesPhysical();
		this.getCasinoZonesVirtual();
		this.getCasinoZonesPlay();
	},
	methods: {
		getCasinoZonesPhysical(){
			axios.get('admin/casino/'+this.$route.params.casino_id+'/zones?type=physical')
				.then(res=>{
					this.dataPhysical = res.data;
				});
		},
		getCasinoZonesVirtual(){
			axios.get('admin/casino/'+this.$route.params.casino_id+'/zones?type=virtual')
				.then(res=>{
					this.dataVirtual = res.data;
				});
		},
		getCasinoZonesPlay(){
			axios.get('admin/casino/'+this.$route.params.casino_id+'/zones?type=play')
				.then(res=>{
					this.dataPlay = res.data;
				});
		}
	}
}
</script>