<template>
<div>
    <div class="card card-green">
        <div class="card_header">
            <span class="float-left">Video Streaming</span>
            <span class="float-right">
                <add-new-item
                    :label='addBtnLabel'
                    @openSpecificCanAdd='openAddNewModal'
                />
            </span>
        </div>
        <crud-table class="gen_table" :data=' dataVideoStream' :fields='fieldsVideoStream' :canEdit="true" :canDelete="true"  @edit="openEditModal" @delete="openDeleteModal"></crud-table>    
    </div>
    <div class="dialogs">
        <!-- Add/Edit Dialog -->
        <v-dialog v-model="editing" width="550">
            <v-card v-if='editing'>
                <div class="modal_header">
                    <p>Video Stream</p>
                    <p v-if="!temp.id">Add New</p>
                    <p v-if="temp.id">Edit</p>
                </div>
                <v-divider class="modal_divider"></v-divider>
                <v-card-text>
                    <br>
                    <form>
                        <div class="fields">
                            <div class="field">
                                <v-text-field 
                                    label="Serial"
                                    v-model="temp.serial"
                                    :rules="[() => !!temp.serial || 'This field is required']"
                                    ref='serial'
                                    outlined
                                />
                                <v-select
                                    label='Manufacturer'
                                    :items="deviceManufacturers"
                                    item-text='name'
                                    item-value='id'
                                    v-model="temp.manufacturerId"
                                    :rules="[() => !!temp.manufacturerId || 'This field is required']"
                                    ref='manufacturer'
                                    outlined
                                />
                                <v-text-field 
                                    label="HW Version"
                                    v-model="temp.hwVersion"
                                    :rules="[() => !!temp.hwVersion || 'This field is required']"
                                    ref='hwVersion'
                                    outlined
                                />
                                <v-text-field 
                                    label="SW Version"
                                    v-model="temp.swVersion"
                                    :rules="[() => !!temp.swVersion || 'This field is required']"
                                    ref='swVersion'
                                    outlined
                                />
                                <v-text-field 
                                    label="API Key"
                                    v-model="temp.apiKey"
                                    :rules="[() => !!temp.apiKey || 'This field is required']"
                                    ref='apiKey'
                                    outlined
                                />
                                <v-text-field 
                                    label="Code"
                                    v-model="temp.videoStreams[0].code"
                                    :rules="[() => !!temp.videoStreams[0].code || 'This field is required']"
                                    ref='code'
                                    outlined
                                />
                                <v-select
                                    label='Video Stream Type'
                                    :items="videoTypes"
                                    item-text='name'
                                    item-value='id'
                                    v-model="temp.videoStreams[0].videoStreamTypeId"
                                    :rules="[() => !!temp.videoStreams[0].videoStreamTypeId || 'This field is required']"
                                    ref='videoType'
                                    outlined
                                />
                                <v-select
                                    label='Video Stream Aspect Ration'
                                    :items="videoAspects"
                                    item-text='name'
                                    item-value='id'
                                    v-model="temp.videoStreams[0].videoStreamAspectRatioId"
                                    :rules="[() => !!temp.videoStreams[0].videoStreamAspectRatioId || 'This field is required']"
                                    ref='videoAspect'
                                    outlined
                                />
                                <v-select
                                    label='Video Stream Resolution'
                                    :items="videoResolutions"
                                    item-text='name'
                                    item-value='id'
                                    v-model="temp.videoStreams[0].videoResolutionId"
                                    :rules="[() => !!temp.videoStreams[0].videoResolutionId || 'This field is required']"
                                    ref='videoRes'
                                    outlined
                                >
                                    <template v-slot:selection='{item}'>{{item.name}} - {{item.width}}x{{item.height}}</template>
                                    <template v-slot:item='{item}'>{{item.name}} - {{item.width}}x{{item.height}}</template>
                                </v-select>
                                <v-checkbox 
                                    label="Mandatory" 
                                    v-model="temp.videoStreams[0].isMandatory"
                                    :rules="[() => !!temp.videoStreams[0].isMandatory || 'This field is required']"
                                    ref='isMandatory'
                                    style='margin-bottom:8px; margin-top: 0px'
                                />
                            </div>  
                        </div>
                    </form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="modal_actions">
                    <v-spacer></v-spacer>
                    <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                        Cancel
                    </v-btn>
                    <v-btn class="modal_btn"  v-if="!temp.id" :elevation="0" color="green" dark @click="createItem(temp)">
                        Add New
                    </v-btn>
                    <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp)">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Delete Modal -->
        <v-dialog v-model="deleting" width="550">
            <v-card>
                <div class="modal_header">
                    <p>Video Stream</p>
                    <p>Delete Item</p>
                </div>
                <v-divider class="modal_divider"></v-divider>
                <v-card-text class="modal_body">
                    <p>Are you sure you want to delete this item ?</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="modal_actions">
                    <v-spacer></v-spacer>
                    <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                        Cancel
                    </v-btn>
                    <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div> 
</div>
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
    components:{
        'add-new-item':AddNewItem,
        'crud-table':CrudTable
    },
    props:['deviceTypeId'],
    data(){
        return {
            dataVideoStream: null, //holds device type data
            deviceManufacturers: null,
            videoTypes:null,
            videoAspects:null,
            videoResolutions:null,
            fieldsVideoStream: [
                {
                    label: 'Serial',
                    getValue: item => item.serial,
                    type: 'text',
                    key: 'serial',
                    ref:'serial',
                    required: true,
                },

                {
                    label: 'Manufacturer',
                    getValue: item => (item.manufacturer) ? item.manufacturer.name : null,
                    type: 'text',
                    key: 'manufacturerId',
                    ref: 'manufacturer',
                    required: true,
                },

                {
                    label:' HW Version',
                    getValue: item => item.hwVersion,
                    type:'text',
                    key:'hwVersion',
                    ref:'hwVersion',
                    required:true
                },
                {
                    label:'SW Version',
                    getValue: item => item.swVersion,
                    type:'text',
                    key:'swVersion',
                    ref:'swVersion',
                    required:true
                },
                {
                    label: 'API Key',
                    getValue: item => item.apiKey,
                    type:'text',
                    key:'apiKey',
                    ref:'apiKey',
                    required:true
                },
                {
                    label: 'Code',
                    type:'hidden',
                    key:'code',
                    ref:'code',
                    required:true
                },
                {
                    label: 'Video Stream Type',
                    type:'hidden',
                    key:'videoType',
                    ref:'videoType',
                    required:true
                },
                {
                    label: 'Video Stream Aspect Ratio',
                    type:'hidden',
                    key:'videoAspect',
                    ref:'videoAspect',
                    required:true
                },
                {
                    label: 'Video Stream Resolution',
                    type:'hidden',
                    key:'videoRes',
                    ref:'videoRes',
                    required:true
                },
                {
                    label: 'Mandatory',
                    type:'hidden',
                    key:'isMandatory',
                    ref:'isMandatory',
                    required:true
                },
                                
            ],
            editing: false,
            deleting: false,
            selected: null,
            loading: false,
			temp:{},
            addBtnLabel:'Add New',
        }
    },
    mounted(){
        this.getVideoStreams();
        this.getDeviceManufacturers();
        this.getVideoStreamTypes();
        this.getVideoStreamAspect();
        this.getVideoStreamResolutions();
    },
    methods:{
        getVideoStreams(){
            axios.get('admin/casino/'+this.$route.params.casino_id+'/devices?typeId='+this.deviceTypeId)
                .then(res=>{
                    this.dataVideoStream = res.data;
                })
        },
        getDeviceManufacturers(){
            axios.get('deviceManufacturers')
            .then(res=>{
                this.deviceManufacturers = res.data;
            })
        },
        getVideoStreamTypes(){
            axios.get('videoStreamTypes')
            .then(res=>{
                this.videoTypes=res.data;
            })
        },
        getVideoStreamAspect(){
            axios.get('videoStreamAspectRatios')
            .then(res=>{
                this.videoAspects=res.data;
            })
        },
        getVideoStreamResolutions(){
            axios.get('videoStreamResolutions')
            .then(res=>{
                this.videoResolutions=res.data;
            })
        },
        validate(){

            let valid = true;

            this.fieldsVideoStream.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);
                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },
        openAddNewModal(){
            this.editing = true;
            this.temp.videoStreams = [{}];
            this.temp.videoStreams[0].isMandatory = false;
        },
        openEditModal(item){
            let obj = JSON.parse(JSON.stringify(item));
            delete obj.billAcceptorChannels;
            delete obj.manufacturer;
            delete obj.type;
            
            this.temp = obj;
            this.editing = true;
        },
        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },
        createItem(item){
          this.loading = true;  
          if(!this.validate()) {
              Vue.toasted.error('Please fill in the form');
              this.loading = false;
              return; 
          }
          item.casinoId = this.$route.params.casino_id;
          item.typeId = this.deviceTypeId;
          axios.post('devices', item)
              .then(res=> {
                  Vue.toasted.success('Item created.');
                  this.getVideoStreams();
                  this.closeModals();
                  this.loading = false;
              })
              .catch(err => {
                  
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error processing your request'); 
                  }

                  this.loading = false;

                });
        },
        updateItem(item) {
            
            this.loading = true;            
            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            
            axios.put('devices/'+item.id, item)
                 .then(res => {

                    Vue.toasted.success('Item updated.');
                    this.closeModals();
                    this.getVideoStreams();                     
                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },
        deleteItem(item) {
            console.log(item);
            axios.delete('devices/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getVideoStreams();
                })
                .catch(err => {
                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error deleting this item.')
                    }
                   
                });

        },
        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
            this.showPicker = false;
        },
    }
}
</script>