<template>
    <div class="tab devices" style="margin-top: 20px;">
        <v-row>
            <v-col cols='12'>
					<ul class="add-device no-padd-left" v-if="deviceTypes">
						<li 
						v-ripple
						@click="openAddNewModal(item)" 
						v-for="item in deviceTypes" 
						:key="item.id" 
						>
						{{item.name}}
						</li>
					</ul>
				</v-col>
        </v-row>
        <v-row v-if="data" data-aos="fade">
            <!-- Video Stream -->
            <v-col xs='12' sm='12' md='3' lg='3' xl='3' v-if='videoData.length !==0'>
                <div class="card card-purple mb-4" v-for='(item,index) in videoData' :key='index'>
                    <div class="card_header sys_info">
                        <span>Video Stream</span>
                        <v-icon color="gray" class='float-right' @click="openDeleteModal(item)">mdi-delete-outline</v-icon>
                    </div>
                    <div class="table_container">
                        <vertical-table :data='item' :fields='videoFields'></vertical-table>
                    </div>

                    <span class='info_card_divider'></span>

                    <span class="vertical_table_header">Video Stream Details</span>
                    <div class="table_container">
                        <vertical-table :data='item' :fields='videoAddFields'></vertical-table>
                    </div>
                </div>
            </v-col>
            <!-- RIB Card -->
            <v-col xs='12' sm='12' md='3' lg='3' xl='3' v-if="ribData.length !==0 ">
                <div class="card card-purple mb-4" v-for='(item,index) in ribData' :key='index'>
                    <div class="card_header sys_info">
                        <span>RIB</span>
                        <v-icon color="gray" class='float-right' @click="openDeleteModal(item)">mdi-delete-outline</v-icon>
                    </div>
                    <div class="table_container">
                        <vertical-table :data='item' :fields='ribFields'></vertical-table>
                    </div>

                    <span class='info_card_divider'></span>

                    <span class="vertical_table_header">Remote Settings</span>
                    <div class="table_container">
                        <vertical-table  :data='item' :fields='ribAddFields'></vertical-table>
                    </div>
                </div>
            </v-col>
            
            <!-- Bill Acceptor si optiune de edit-->
            <v-col xs='12' sm='12' md='3' lg='3' xl='3' v-if='billData.length !==0'>
                <div class="card card-purple mb-4" v-for='(item,index) in billData' :key='index'>
                    <div class="card_header sys_info">
                        <span>Bill Acceptor</span>
                        <v-icon color="gray" class='float-right' @click="openDeleteModal(item)">mdi-delete-outline</v-icon>
                    </div>
                    <div class="table_container">
                        <vertical-table :data='item' :fields='billFields'></vertical-table>
                    </div>

                    <span class='info_card_divider'></span>

                    <span class="vertical_table_header">Channels</span>
                    <div class="table_container channel_table">
                        <crud-table :data='item.device.billAcceptorChannels' :fields='billAddFields' :canEdit="true" :canDelete="true" @edit="openEditChannelModal" @delete="openDeleteChannelModal"></crud-table>
                    </div>
                </div>
            </v-col>
            <!-- Touch controller -->
            <v-col xs='12' sm='12' md='3' lg='3' xl='3' v-if='touchData.length !==0'>
                <div class="card card-purple mb-4" v-for='(item,index) in touchData' :key='index'>
                    <div class="card_header sys_info">
                        <span>Touch Controller</span>
                        <v-icon color="gray" class='float-right' @click="openDeleteModal(item)">mdi-delete-outline</v-icon>
                    </div>
                    <div class="table_container">
                        <vertical-table :data='item' :fields='touchFields'></vertical-table>
                    </div>

                    <span class='info_card_divider'></span>

                    <span class="vertical_table_header">Settings</span>
                    <div class="table_container">
                        <vertical-table :data='item' :fields='touchAddFields'></vertical-table>
                    </div>
                </div>
            </v-col>
        </v-row>    
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if="editing">
                    <div class="modal_header">
                        <p>Device</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" v-if='!editChannel'>
                                    <v-select
                                    v-if="deviceTypes"
                                    :items='deviceTypes'
                                    item-text="name"
                                    item-value="id"
                                    label="Device Type"
                                    v-model="selectedDeviceType"
                                    :rules="[() => !!selectedDeviceType || 'This field is required']"
                                    @change="getCasinoDevicesByType()"
                                    ref='selectedDeviceType'
                                    outlined
                                    disabled
                                    />
                                    <!-- Video Stream & Control Board -->
                                    <v-select 
                                    v-if='selectedDeviceType!==4 && selectedDeviceType!==5'
                                    :disabled="!casinoDevices" 
                                    :items="(casinoDevices) ? casinoDevices : []"
                                    item-text="serial" 
                                    item-value='id'
                                    label="Device"
                                    v-model="temp.DeviceId"
                                    :rules="[() => !!temp.DeviceId || 'This field is required']"
                                    ref='DeviceId'
                                    outlined/>
                                    <!-- Bill Acceptor -->
                                    <v-select 
                                    v-if='selectedDeviceType===4'
                                    :items="billAcceptorTemplates"
                                    item-text="name" 
                                    item-value='id'
                                    label="Device"
                                    v-model="temp"
                                    :rules="[() => !!temp || 'This field is required']"
                                    ref='DeviceId'
                                    outlined
                                    return-object
                                    />
                                    <!-- Touch Controller -->
                                    <v-select 
                                    v-if='selectedDeviceType===5'
                                    :items="touchControllers"
                                    item-text="serial" 
                                    item-value='id'
                                    label="Device"
                                    v-model="temp.deviceId"
                                    :rules="[() => !!temp.deviceId || 'This field is required']"
                                    ref='DeviceId'
                                    outlined
                                    />
                                </div> 
                                <div class="field" v-if='editChannel'>
                                          <v-text-field 
                                            label="Channel"
                                            v-model="temp.channel"
                                            :rules="[() => !!temp.channel || 'This field is required']"
                                            ref='channel'
                                            outlined
                                          />
                                          <v-text-field 
                                            label="Value"
                                            v-model="temp.value"
                                            :rules="[() => !!temp.value || 'This field is required']"
                                            ref='value'
                                            outlined
                                          />
                                          <v-checkbox 
                                            label="Enabled" 
                                            v-model="temp.isEnabled"
                                            ref='isEnabled'
                                            style='margin-bottom:8px; margin-top: 0px'
                                          />
                                        </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn"  v-if='selectedDeviceType!==4 && !editChannel' :elevation="0" color="green" dark @click="createItem(temp)">
                            Add New Link
                        </v-btn>
                        <v-btn class="modal_btn"  v-if='selectedDeviceType===4 && !editChannel' :elevation="0" color="green" dark @click="createBill">
                            Add New Link
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && editChannel" :elevation="0" color="green" dark @click="updateChannel">
                            Save
                        </v-btn>
                        <!-- <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save and Close
                        </v-btn> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Device</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" v-if='!editChannel' :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                        <v-btn class="modal_btn" v-if='editChannel' :elevation="0" color="red" dark @click="deleteChannel">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>                  
    </div>
</template>
<script>
import VerticalTableData from '@/components/tables/VerticalTableData.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
    components: {
        'vertical-table':VerticalTableData,
        'crud-table' :CrudTable
    },
    props:['egmData'],
    data(){
        return { 
            data: null, //Mix configuration games of one egm
            casinoDevices:null, //holds all devices for a casino except Bill Acceptors

            billAcceptorTemplates:null,
            touchControllers:null,

            deviceTypes:null,
            selectedDeviceType:null,

            ribData:null,
            videoData:null,
            billData:null,
            touchData:null,
            
            fields:[
                {
                    label: 'Serial No',
                    type: 'text',
                    key: 'serial',
                    ref: 'DeviceId',
                    required: true
                }
            ],
            ribFields: [
                {
                    label:'Manufacturer:',
                    getValue:item =>item.device.manufacturer.name
                },
                {
                    label:'Serial:',
                    getValue:item =>item.device.serial
                },
                {
                    label:'HW Version:',
                    getValue:item =>null,
                },
                {
                    label:'SW Version:',
                    getValue:item =>item.device.swVersion
                },
                {
                    label:'Mac Address:',
                    getValue:item =>item.device.macAddress
                },
                {
                    label:'API Key:',
                    getValue:item =>null
                }
            ],
            ribAddFields: [
                {
                    label:'Audit Interval:',
                    getValue:item =>item.device.remoteSettings.auditInterval
                },
                {
                    label:'Status Interval:',
                    getValue:item =>item.device.remoteSettings.statusInterval
                },
                {
                    label:'MinCreditStartSession:',
                    getValue:item =>item.device.remoteSettings.minCreditStartSession
                },
            ],
            videoFields: [
                {
                    label:'Manufacturer:',
                    getValue:item =>item.device.manufacturer.name
                },
                {
                    label:'Serial:',
                    getValue:item =>item.device.serial
                },
                {
                    label:'HW Version:',
                    getValue:item =>null,
                },
                {
                    label:'SW Version:',
                    getValue:item =>item.device.swVersion
                }
            ],
            videoAddFields:[
                {
                    label:'Code:',
                    getValue:item =>item.device.type.code
                },
                {
                    label:'isMandatory:',
                    getValue:item =>(item.device.videoStreams[0].isMandatory) ? 'Yes' : 'No'
                },
                {
                    label:'Type:',
                    getValue:item =>item.device.type.name
                },
                {
                    label:'Resolution:',
                    getValue:item =>item.device.videoStreams[0].videoResolution.name + ' - '+item.device.videoStreams[0].videoResolution.width+'x'+item.device.videoStreams[0].videoResolution.height
                },
            ],
            billFields:[
                {
                    label:'Manufacturer:',
                   getValue:item =>item.device.manufacturer.name
                },
                {
                    label:'Serial:',
                    getValue:item =>item.device.serial
                },
                {
                    label:'HW Version:',
                    getValue:item =>null,
                },
                {
                    label:'SW Version:',
                    getValue:item =>item.device.swVersion
                },
                {
                    label:'Protocol:',
                    getValue:item =>item.device.macAddress
                }
            ],
            billAddFields:[
                {
                    label: 'Channel',
                    getValue: item => item.channel,
                    type: 'text',
                    key:'channel',
                    ref: 'channel',
                    required:true,
                },
                {
                    label: 'Value',
                    getValue: item => item.value,
                    type: 'text',
                    key:'value',
                    ref: 'value',
                    required:true,
                },
                {
                    label: 'Enabled',
                    getValue: item => (item.isEnabled)? 'on':'off',
                    getClass: item => (item.isEnabled) ? 'isEnabled' : 'isDisabled',
                    type: 'text',
                    key:'isEnabled',
                    ref: 'isEnabled',
                    required:false,
                },
                
            ],
            touchFields:[
                {
                    label:'Manufaturer:',
                    value:item=>item.device.manufacturer.name
                },
                {
                    label:'Serial:',
                    value:item=>item.device.serial
                },
                {
                    label:'version',
                    getValue:item =>null
                },
                {
                    label:'Protocol:',
                    value:item=>item.device.protocol.name
                },
            ],
            touchAddFields:[
                {
                    label:'isEnabled',
                    value:item=>item.device.touchSettings.isEnabled
                },
                {
                    label:'Resolution',
                    value:item=>(item.device.touchSettings.videoResolution.width+'x'+item.device.touchSettings.videoResolution.height )
                }
            ],

            editing: false,
            editChannel: false,
            deleting: false,
            selected: null,
            loading: false,
            temp:{},
            addBtnLabel:'Add New'
        }
    },
    computed:{
        
    },
    mounted(){
        this.getEgmDevices();
        this.getDeviceTypes();
        this.getBillAcceptorTemplates();
        this.geTouchControllers();
    },
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
                this.selectedDeviceType = null;
            }
        },
    },
    methods: {
        getEgmDevices(){
            axios.get('admin/egms/'+this.$route.params.egm_id+'/devices')
                .then(res=>{
                    this.data = res.data;

                    this.ribData=this.data.filter(item=>item.device.typeId === 2);
                    this.videoData=this.data.filter(item=>item.device.typeId === 1);
                    let tempBillData = this.data.filter(item=>item.device.typeId === 4);

                    tempBillData.forEach(billAcceptor => {
                        let temp = billAcceptor.device.billAcceptorChannels.filter(channel => channel.status !== 'Deleted');
                        console.log(temp);
                        billAcceptor.device.billAcceptorChannels = temp;
                    });

                    this.billData = tempBillData;
                    this.touchData=this.data.filter(item=>item.device.typeId === 5);
                });
        },
        getBillAcceptorTemplates(){
            axios.get('infrastructure/billAcceptors?pageIndex=0&pageSize=9999')
            .then(res=>{
                this.billAcceptorTemplates = res.data.data;
            })
        },
        geTouchControllers(){
            axios.get('infrastructure/touchControllers?pageIndex=0&pageSize=9999')
            .then(res=>{
                this.touchControllers = res.data.data;
            })
        },
        getCasinoDevicesByType(){
            axios.get('admin/casino/'+this.egmData.casinoId+'/devices?typeId='+this.selectedDeviceType)
            .then(res=>{
                this.casinoDevices = res.data
            })
        },
        getDeviceTypes(){
             axios.get('deviceTypes')
            .then(res=>{
                this.deviceTypes = res.data
            })
        },
        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        console.log('invalid', field);
                    }

                }
            });

          return valid;
        },
        openAddNewModal(item){
            this.editing = true;
            this.selectedDeviceType = item.id;
            this.getCasinoDevicesByType();
        },
        openEditChannelModal(item){
            this.editing = true;
            this.editChannel = true;
            this.temp = JSON.parse(JSON.stringify(item));
        },
        updateChannel(){
            this.loading = true;
            if(!this.validate(this.billAddFields)) {
                Vue.toasted.error('Please fill in the form');
                this.loading = false;
                return; 
            }
            let tempBill = this.billData.filter(billAcceptor => billAcceptor.device.billAcceptorChannels.filter(channel=>channel.id ===this.temp.id)[0].id === this.temp.id)[0];
            delete tempBill.device.manufacturer;
            delete tempBill.device.type;
            delete tempBill.device.videoStreams;

            let cleanItem = tempBill.device;
            let channelIndex = cleanItem.billAcceptorChannels.findIndex(channel=>channel.id === this.temp.id);
            cleanItem.billAcceptorChannels[channelIndex] = this.temp;
            console.log(cleanItem);

            axios.put('devices/'+cleanItem.id, cleanItem)
                .then(res=>{
                    Vue.toasted.success('Item updated.');
                    this.getEgmDevices();
                    this.closeModals();
                    
                    this.loading = false;
                
                })
              .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

              });
        },
        openDeleteChannelModal(item){
            this.editChannel = true;
            this.selected = JSON.parse(JSON.stringify(item));
            this.deleting = true;
        },
        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },
        createItem(temp, keepOpen){
            this.temp['EGMachineId'] = this.egmData.id;
            this.loading = true;
            
             if(!this.validate(this.fields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

            axios.post('egmDevices', temp)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                     this.getEgmDevices();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                    this.$emit('refreshStats');
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },
        createBill(){            
            let cleanItem = {
                casinoId: this.egmData.casinoId,
                typeId: this.selectedDeviceType,
                serial: this.temp.name,
                manufacturerId: this.temp.manufacturerId,
                billAcceptorChannels: this.temp.billAcceptorChannels,
                status: this.temp.status
            }

            console.log(cleanItem);
    
            this.loading = true;

            axios.post('devices', cleanItem)
                .then(res=> {
                    
                    let createdBill = res.data;
                    let linkBill = {
                        deviceId : createdBill.id,
                        egMachineId : this.$route.params.egm_id
                    }

                    axios.post('egmDevices', linkBill)
                        .then(res=>{
                            Vue.toasted.success('Item created.');
                            this.getEgmDevices();
                            this.closeModals();
                            this.loading = false;
                            this.$emit('refreshStats');
                        })
                        .catch(err => {

                        if(err.response.data.title) {
                            Vue.toasted.error(err.response.data.title); 
                        } else {
                            Vue.toasted.error('There was an error processing your request'); 
                        }

                        this.loading = false;

                        })
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },
        deleteChannel(){
            this.loading = true;
            let tempBill = this.billData.filter(billAcceptor => billAcceptor.device.billAcceptorChannels.filter(channel=>channel.id ===this.temp.id)[0].id === this.temp.id)[0];
            delete tempBill.device.manufacturer;
            delete tempBill.device.type;
            delete tempBill.device.videoStreams;

            let cleanItem = tempBill.device;
            let channelIndex = cleanItem.billAcceptorChannels.findIndex(channel=>channel.id === this.temp.id);
            this.selected.status = 'Deleted';
            cleanItem.billAcceptorChannels[channelIndex] = this.selected;
            //console.log(cleanItem);

            axios.put('devices/'+cleanItem.id, cleanItem)
                .then(res=>{
                    Vue.toasted.success('Item updated.');
                    this.getEgmDevices();
                    this.closeModals();
                    
                    this.loading = false;
                
                })
              .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

              });
        },
        deleteItem(item) {
            console.log(item);
            axios.delete('egmDevices/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getEgmDevices();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },
        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.editChannel = false;
            this.deleting = false;
        },
    }
}
</script>