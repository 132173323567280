<template>
    <ul class="toggle_btn">
        <li v-ripple @click='onClickOne(toggleButtonNames[0])' v-bind:class="{toggle_btn_active:isActive === true}">{{toggleButtonNames[0]}}</li>
        <li v-ripple @click='onClickSecond(toggleButtonNames[1])' v-bind:class="{toggle_btn_active:isActive === false}">{{toggleButtonNames[1]}}</li>
    </ul>
</template>
<script>
export default {
    props:['toggleButtonNames'],
    data(){
        return {
           isActive: ''
        }
    },
    mounted(){
        this.isActive = true;
    },
    methods:{
        onClickOne(event){
            this.isActive = !this.isActive;
            this.$emit('first', event);
        },
        onClickSecond(event){
            this.isActive = !this.isActive;
            this.$emit('second', event);
        }
    }
}
</script>