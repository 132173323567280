<template>
    <div class="tab mix" style="margin-top: 20px;">
        <v-row>
            <v-col col="12">
                <div class="card card-purple" v-if="data" data-aos="fade">
                        <div class="card_header">
                        <span class="float-left">Games</span>
                        <span class="float-right">
                            <add-new-item
                            :label='addBtnLabel'
                            @openSpecificCanAdd='openAddNewModal'
                            />
                        </span>
                    </div>
                    <crud-table class="gen_table" :data='data' :fields='fields' :canEdit="true" :canDelete="true" @edit="openEditModal" @delete="openDeleteModal"></crud-table>       
                </div>  
            </v-col>
        </v-row> 
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if="editing"> 
                    <div class="modal_header">
                        <p>Game</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" v-if='!temp.id'>
                                    <v-select
                                        v-if="games"
                                        :items='games'
                                        ref="gameId"
                                        item-text="name"
                                        item-value="id"
                                        label="Game"
                                        v-model="temp.GameId"
                                        :rules="[() => !!temp.GameId || 'This field is required']"
                                        @change="getGameRTPs(temp.GameId)"
                                        outlined
                                    />
                                    <v-select
                                        :disabled="!gameRtps"                                       
                                        :items='(gameRtps) ? gameRtps : []'
                                        ref="gameRtps"
                                        item-text="value"
                                        item-value="id"
                                        label="RTP"
                                        v-model="temp.GameRTPId"
                                        :rules="[() => !!temp.GameRTPId || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-text-field 
                                        ref="SASCode"
                                        label="SAS Code"
                                        v-model="temp.SASCode"
                                        :rules="[() => !!temp.SASCode || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-text-field 
                                        label="SAS Name"
                                        ref="SASName"
                                        v-model="temp.SASName"
                                        :rules="[() => !!temp.SASName || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-text-field 
                                        label="Volatility"
                                        ref="volatility"
                                        v-model.number="temp.volatility"
                                        :rules="[() => !!temp.volatility || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-checkbox 
                                        label="Enabled" 
                                        v-model="temp.IsEnabled"
                                        />
                                </div> 
                                <div class="field" v-if='temp.id'>
                                    <v-select
                                        v-if="games"
                                        :items='games'
                                        item-text="name"
                                        item-value="id"
                                        label="Game"
                                        ref='gameId'
                                        v-model="temp.gameId"
                                        :rules="[() => !!temp.gameId || 'This field is required']"
                                        outlined
                                        disabled
                                    />
                                    <v-select
                                        v-if="gameRtps"                                       
                                        :items='gameRtps'
                                        item-text="value"
                                        item-value="id"
                                        label="RTP"
                                        ref="gameRtps"
                                        v-model="temp.gameRTPId"
                                        :rules="[() => !!temp.gameRTPId || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-text-field 
                                        label="SAS Code"
                                        ref="SASCode"
                                        v-model="temp.sasCode"
                                        :rules="[() => !!temp.sasCode || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-text-field 
                                        label="SAS Name"
                                        ref="SASName"
                                        v-model="temp.sasName"
                                        :rules="[() => !!temp.sasName || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-text-field 
                                        label="Volatility"
                                        ref='volatility'
                                        v-model.number="temp.volatility"
                                        :rules="[() => !!temp.volatility || 'This field is required']"
                                        required
                                        outlined
                                    />
                                    <v-checkbox 
                                        label="Enabled" 
                                        v-model="temp.isEnabled"
                                        />
                                </div> 
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="!temp.id" :elevation="0" color="green" dark @click="createItem(temp)">
                            Add New
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Game</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>                  
    </div>
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTable from '@/components/tables/CrudTable.vue';

export default {
    components: {
        'add-new-item': AddNewItem,
        'crud-table':CrudTable,
    },
    props:['egmData'],
    data(){
        return {

            data:null, //Mix configuration games of one egm

            games:null, //Holds games based on one manufacturer
            gameRtps:null, //RTPs for individual game

            editing: false,

            deleting: false,

            selected: null,

            loading: false,

            temp: {},

            fields: [
                {
                    label: 'Game',
                    getValue: item => item.game.name,
                    type: 'text',
                    key: 'gameId',
                    required: true,
                    ref: 'gameId'
                },
                {
                    label: 'SAS Code',
                    getValue: item => item.sasCode,
                    type: 'text',
                    key: 'sasCode',
                    required: true,
                    ref: 'SASCode',
                },
                {
                    label: 'SAS Name',
                    getValue: item => item.sasName,
                    type: 'text',
                    key: 'sasName',
                    required: true,
                    ref: 'SASName',
                },
                {
                    label: 'Game RTP (%)',
                    getValue: item => item.gameRTP.value,
                    type: 'text',
                    key: 'gameRTP',
                    required: true,
                    ref: 'gameRtps',
                },
                {
                    label: 'volatility',
                    getValue: item => item.volatility,
                    type: 'text',
                    key: 'volatility',
                    required: true,
                    ref: 'volatility',
                },
                {
                    label: 'Enabled',
                    getValue: item => (item.isEnabled ==='True') ? 'on' : 'off',
                }
            ],
            addBtnLabel:'Add New'
        }
    },
    mounted(){
        this.getEgmGames();
        this.getGamesbyManufacturer();
    },
     watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
                this.gameRtps = null;
            }
        },
    },
    methods: {
        getEgmGames(){
            axios.get('admin/egms/'+this.$route.params.egm_id+'/games')
                .then(res=>{
                    this.data = res.data
                });
        },
        getGamesbyManufacturer(){
            axios.get('egmManufacturers/'+this.egmData.manufacturerId+'/games')
                .then(res=>{
                    this.games = res.data
                });
        },
        getGameRTPs(param){
            axios.get('games/'+param+'/rtps')
                .then(res=>{
                    this.gameRtps = res.data
                });
        },
        openAddNewModal(){
            this.temp = {};
            this.editing = true;
        },
        openEditModal(item){
            this.temp = Object.assign({},item);
            this.temp.isEnabled = JSON.parse(this.temp.isEnabled.toLowerCase());
            this.editing = true;
            this.getGameRTPs(this.temp.gameId);
            
        },
        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },
        validate(){

            let valid = true;

            this.fields.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    
                
                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },
        createItem(item, keepOpen){

            item['EGMachineId'] = this.egmData.id;

            this.loading = true;
            console.log(item);

            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

            axios.post('egmGames', item)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                     this.getEgmGames();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                    this.$emit('refreshStats');
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },
        updateItem(item, keepOpen) {

            // Validation 
            this.loading = true;            
            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            

            // Remove all child objects
            // from the model before seding it to the API
            var cleanItem = Object.assign({}, item);
            for (let prop in cleanItem) {
                if(typeof cleanItem[prop] == 'object') {
                    delete cleanItem[prop];
                }
            }
            console.log(cleanItem);
            axios.put('egmGames/'+cleanItem.id, cleanItem)
                 .then(res => {

                    Vue.toasted.success('Item created.');
                    this.getEgmGames();

                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },
        deleteItem(item) {
            axios.delete('egmGames/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getEgmGames();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },
        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
        },
    }
}
</script>