<template>
    <div class="page egms">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if="egmData" data-aos="fade">
                            <div class="title">{{egmData.manufacturer.name}} {{egmData.model.name}}</div>
                            <div class="subtitle">Overview</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <v-btn :elevation="0" class="print_btn float-right"><v-icon>mdi-printer</v-icon> Print</v-btn>
                            <v-btn :elevation="0" class="red_btn float-right">Lock</v-btn>
                            <toggle-button :toggleButtonNames='toggleButtonNames' class="float-right"></toggle-button>
                        </div>
                        
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if="egmStats" data-aos="fade">
					<v-col cols='12'>
                        <v-slide-group 
                        show-arrows
                        >
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card :elevation="0" class="nav_card_basic">
                                    <p>{{item.label}}</p>
                                    <p>{{item.value}}</p>	
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
					</v-col>
				</v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='3' lg='3' xl='3' class="side_bar">
                        <span class="side_bar_info">Key Info</span>

                        <div class="card card-blue" v-if="egmData" data-aos="fade">                            
                            <div class="card_header sys_info">
                                <span>SysInfo</span>
                            </div>
                            <div class="table_container">
                                <vertical-table :sysInfo='sysInfoFields'/>
                            </div>
                        </div>
                    </v-col>

                    <v-col xs='12' sm='12' md='9' lg='9' xl='9'>
                        <div class="navigation navigation-purple">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view v-if="egmData" :egmData="egmData" @refreshStats='getEgmStats'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import VerticalTable from '@/components/tables/VerticalTable.vue'
import ToggleButton from '@/components/navigation/ToggleButton.vue'

export default {
    components:{
        'tab-nav': TabNav,
        'vertical-table': VerticalTable,
        'toggle-button': ToggleButton
    },
    data(){
        return {
            egmData:null,
            egmStats:null,
            tabNames: [
                {
                    label:'Mix Configuration',
                    route:'mix'
                },
                {
                    label:'Zones',
                    route:'zone'
                },
                {
                    label:'Devices',
                    route:'device'
                },
                {
                    label:'History',
                    route:'history'
                }
            ],
            toggleButtonNames:['On','Off']
        }
    },
    computed:{
        sysInfoFields: function() {
            return [
                {
                    label:'Machine No:',
                    value: this.egmData.number
                },
                {
                    label:'Cabinet',
                    value: this.egmData.model.name
                },
                {
                    label:'Machine RTP:',
                    value: this.egmData.rtp
                },
                {
                    label:'Game Mix:',
                    value: this.egmData.gameMix.name
                },
                {
                    label:'Denomination:',
                    value: this.egmData.denomination
                },
                {
                    label:'EEPROM Version:',
                    value: this.egmData.eepromVersion
                },
                {
                    label:'Game Mix Volatility:',
                    value: this.egmData.gameMix.volatility
                },
                {
                    label:'Checksum:',
                    value: this.egmData.checksum
                },
            ]
        },
        cardInfo:function() {
            return [
                {
                    label:'Games',
                    value: this.egmStats.numberOfGames
                },
                {
                    label:'Zones',
                    value: this.egmStats.numberOfZones
                },
                {
                    label: 'Devices',
                    value: this.egmStats.numberOfDevices
                }
            ]
        },
    },
    mounted(){
        this.getEgms();
        this.getEgmStats();
    },
    methods: {
        getEgms(){
            axios.get('admin/egms/'+ this.$route.params.egm_id)
                .then(res=>{
                    this.egmData = res.data;          
                });
        },
        getEgmStats(){
            axios.get('admin/egms/'+this.$route.params.egm_id+'/stats')
                .then(res=>{
                    this.egmStats = res.data;
                });
        }
    }
}
</script>